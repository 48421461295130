@import '../../style/main.scss';

.workwithus {
  background-color: $oldMauve;
  text-align: left;
  .workwithus-container {
    padding: 10vh 40px;
    color: $white;
    position: relative;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .workwithus-text {
    margin-bottom: 50px;
  }
  // .workwithus-content {
  //   position: relative;
  // }
  .workwithus-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 24px;
      margin: 1.5em 0;
      @media(max-width: 991px) {
        font-size: 16px;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 65px;
    }
  }
  .workwithus-image {
    position: absolute;
    bottom: -200px;
    max-width: 500px;
    width: 100%;
    @media (max-width: 768px) {
      bottom: -180px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .padding-left {
    padding-left: 8%;
  }
  .workwithus-absolute {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .workwithus-absolute-mobile {
    display: none;
    @media(max-width: 768px) {
      display: block;
    }
  }
}