@import '../../style/main.scss';

.navbar {
  background-color: #fff;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1440px;
  padding: 10px;
  width: 100%;
  .navbar-pc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .navbar-list {
    list-style: none;
    li {
      display: inline-block;
      vertical-align: middle;
      padding: 26px 15px 5px;
      cursor: pointer;
      &.active, &:hover {
        text-decoration: underline;
        color: $brightMaroon;
        font-weight: bold;
      }
      a {
        &.active {
          text-decoration: underline;
          color: $brightMaroon;
          font-weight: bold;
        }
      }
    }
  }
  .navbar-link {
    margin: 0 5px;
  }
  .navbar-logo {
    width: 50%;
    text-align: left;
    img {
      max-width: 100%;
    }
  }
  .menu {
    cursor: pointer;
    border-radius: 5px;
    text-align: right;
    padding: 5px 6px;
    color:#fff;
    background-color: #D8446B;
  }
  .navbar-mobile {
    @media(min-width: 991px) {
      display: none;
    }
    @media(max-width: 991px) {
      padding: 10px 10px;
      display: flex;
      justify-content: space-between;
    }
  }
  .navbar-item {
    display: none;
    &.open {
      display: block;
      width: 100%;
      position: absolute;
      top: 70px;
      left: 0;
      li {
        font-family: 'manifestor' !important;
        &.active {
          text-decoration: underline;
        }
        &:active, &.ant-menu-item-active  {
          background-color: #D8446B !important;
          color: #fff !important; 
        }
        &.ant-menu-item-selected {
          color: #fff;
          background-color: #D8446B;
        }
      }
    }
  }
  
 
}