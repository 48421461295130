@font-face {
  font-family: "manifestor";
  src: local("manifestor"),
   url("../fonts/manifestor-medium.ttf") format("truetype");
  }

$white: #ffffff;
$brightMaroon: #bb2649;
$oldMauve: #652244;
$castletonGreen: #0A5945;
$shimmeringBlush: #D67D92;
$vanetianRed: #F46103;

body {
  font-family: 'manifestor' !important;
}

h1 {
  font-size: 128px;
  @media(max-width: 1024px) {
    font-size: 64px;
  }
}
h2 {
  font-size: 64px;
  @media(max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 991px) {
    font-size: 36px;
  }
}
h3 {
  font-size: 40px;
  @media(max-width: 1024px) {
    font-size: 32px;
  }
}
h4 {
  font-size: 32px;
  @media(max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 991px) {
    font-size: 20px;
  }
}
p {
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 991px) {
    line-height: 24px;
    font-size: 16px;
  }
}
h1,h2,h3,p {
  margin: 0;
}
.text-maroon {
  color: $brightMaroon;
}

.text-mauve {
  color: $oldMauve;
}

.text-bold {
  font-weight: bold;
}

.text-green {
  color: $castletonGreen;
}

.full-image {
  img {
    max-width: 100%;
  }
}
.text-white {
  color: $white
}

.text-small {
  font-size: 20px;
  line-height: 28px;
  @media (max-width: 1100px) {
    font-size: 16px;
  }
}

h1, h2, h3, h4, p {
  font-family: 'manifestor';
}