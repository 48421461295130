@import '../../style/main.scss';

.meetfounder {
  background-color: $brightMaroon;
  display: flex;
  color: $white;
  position: relative;
  align-items: center;
  .meetfounder-wrapper {
    height: 100%;
  }
  .meetfounder-content {
    padding: 10vh 96px 10vh 43px;
    text-align: left;
    align-self: center;
    @media (max-width: 1200px) {
      padding: 8vh 10px 10vh 43px;
    }
    @media (max-width: 991px) {
      padding: 5vh 0 5vh 43px;
    }
    @media (max-width: 768px) {
      text-align: center;
      padding: 7vh 4vh;
      position: relative;
      z-index: 2;
    }
  }
  .meetfounder-img {
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 550px;
      @media (max-width: 1100px) {
        max-width: 500px;
      }
      @media(max-width: 991px) {
        position: relative;
        max-width: 345px;
      }
      @media(max-width: 768px) {
        max-width: 100%;
      }
      // @media (max-width: 410px) {
      //   max-width: 100%;
      // }
    }
  }
  .pattern {
    position: absolute !important;
    right: 0;
    top: -60px;
    width: 100%;
    max-width: 500px;
    @media (max-width: 991px) {
      top: -40px;
    }
    @media (max-width: 768px) {
      top: 0;
    }
  }
}