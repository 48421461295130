@import '../../style/main.scss';

.ourservice {
  padding: 86px 41px;
  background-color: $white;
  min-height: 100vh;
  position: relative;
  @media (max-width: 768px) {
    padding: 120px 41px; 
  }
  .ourservice-container {
    padding: 50px 0;
  }
  .ourservice-content {
    text-align: left;
    align-self: center;
  }
  .padding-left {
    padding-left: 8%;
    @media(max-width: 768px) {
      padding-left: 0;
    }
  }
  .padding-right {
    padding-right: 8%;
  }
  .column-reverse {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  .margin-bottom {
    @media(max-width: 768px) {
      margin-bottom: 30px;
    }
  }
}