@import '../../style/main.scss';
.first-section {
  background-color: $white;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 100px);
  justify-content: center;
  padding: 150px 0 0;
  @media (max-width: 991px) {
    padding: 130px 30px 50px;
  }
  .text-content {
    margin: 90px 45px;
  }
  .first-section-title {
    margin: 0;
  }
}