.aboutus {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: left;
  font-family: 'manifestor' !important;
  @media(max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    padding: 120px 0;
  }
  .aboutus-container {
    padding: 0 40px;
    @media (max-width: 991px) {
      padding: 0 15px;
      text-align: center;
    }
  }
  .aboutus-img {
    img {
      max-width: 100%;
      text-align: center;
    }
    @media(max-width: 991px) {
      margin-bottom: 45px;
    }
  }
  .aboutus-content {
    padding-left: 9%;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
}