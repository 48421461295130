@import '../../style/main.scss';

.solution {
  padding: 20vh 0 30vh;
  background-color: $white;
  @media(max-width: 991px) {
    padding: 25vh 0 10vh;
  }
  .solution-container {
    padding: 0 40px;
  }
  .solution-card {
    padding: 0 16px;
  }
  .text-title {
    color: $vanetianRed;
    font-weight: bold;
    text-align: left;
    height: 110px;
    @media (max-width: 1260px) {
      font-size: 24px;
    }
    @media (max-width: 991px) {
      height: auto;
    }
  }
  .solution-content {
    height: 185px;
  }
  .card-content {
    margin: 0 10px;
    border-radius: 30px;
    text-align: left;
    height: 640px;
    box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.05);
  }
  .ant-carousel {
    .slick-dots {
      li {
        height: 10px;
        width: 79px;
        border-radius: 50px;
        button {
          background: $shimmeringBlush;
          height: 10px;
          border-radius: 50px;
        }
        &.slick-active {
          width: 127px;
          button {
            background: $brightMaroon;
          }
        }
      }
    }
    .slick-dots-bottom {
      bottom: -80px;
    }
  }
}