@import '../../style/main.scss';

.contactus {
  height: 100vh;
  background-color: $brightMaroon;
  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
  }
  .contactus-wrapper {
    display: flex;
    height: 100%;
    @media (max-width: 991px) {
      padding: 60px 0;
    }
    @media (max-width: 768px) {
      padding-top: 100px;
    }
  }
  .contactus-content {
    display: flex;
    position: relative;
    z-index: 2;
    height: 100%;
    align-items: center;
    // padding: 16vh 40px;
    color: $white;
    @media (max-width: 991px) {
      margin-bottom: 90px;
    }
  }
  .text-left {
    text-align: left;
  }
  .contactus-form-wrapper {
    text-align: left;
    background-color: $white;
    padding: 27px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 80%;
    margin: -50px auto 0;
    position: relative;
    z-index: 2;
    input, textarea {
      &:focus, &:hover {
        border-color: #D8446B;
      }
    }
  }
  .contactus-address {
    margin: 50px auto;
  }
  .contactus-padding {
    padding: 0 30px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  .contactus-btn {
    background-color: #D8446B;
    color: $white;
    &:hover {
      background-color: #D8446B;
    }
  }
  .contactus-content {
    img {
      max-width: 100%;
    }
  }
  .pattern-contact {
    img {
      position: absolute;
      top: -130px;
      right: 0;
      z-index: 1;
      max-width: 400px;
      @media(max-width: 991px) {
        max-width: 350px;
      }
    }
  }
  .contactus-detail {
    padding: 0 35px;
  }
  .margin-top {
    margin: 30px auto;
  }
}